module.exports = {
    brands: [
        {
            name: 'iforex',
            clientId: 'B6FBEEBF-FE33-42D3-B0B8-83BF44F3C192',
            masterDomain: '"events.iforex.com"',
            //broker: '/iforex\.(hu|cz)$/.test(document.domain) ? "2" : "1"',
            tlds: "'com','co.il','co.id','hu','gr','ro','com.ru','jpn.com','ae','in','cz','fr','it','es','my','nl','eu','be','pl','co','com.tr','com.cy','ph','labolsa.com','se','mx'",
            crossDomains: {
                countries: {
                    SA: false,
                    CN: false
                }
            },
            brokers: [1,2]
        },
        {
            name: 'vestle',
            clientId: 'C075CE19-7294-4D60-B6CB-6BB7F63FDE14',
            masterDomain: 'document.domain',
            //broker: '/vestle\.co\.uk$/.test(document.domain) ? "33" : "3"',
            tlds: "'com', 'gr', 'fr','it','es','nl','pl','se','co.uk'",
            crossDomains: false,
            brokers: [3,33]
        },
        {
            name: 'toredofx',
            clientId: '92fb10a8-eb9f-49ae-a188-685f4d9eabda',
            masterDomain: 'document.domain',
            tlds: "'kr', 'com'",
            crossDomains: false,
            brokers: [108]
        }
    ]
};
