import { brands } from '../config';

const EC = () => {
    function getParam(d){var f=new RegExp("[\x26?]"+d+"\x3d([^\x26]*)","i");var e=document.location.search.match(f);return e!=null&&e.length>1?e[1]:""}
    function readCookie(a){
        let res = null;
        let  re =new RegExp("(?:^| )"+a+"\x3d([^;]*)","i");
        try { 
            let m = document.cookie.match(re); 
            res = m && 2==m.length ? m [1]:null;
        } catch(x){}
        return res;
    }
    function getQueryStringParameters() {
        let scripts = document.getElementsByTagName('script');

        let args = [], query = {};
        for(let i=0;i<scripts.length; i++) {
            let parser = document.createElement('a');
            parser.href = scripts[i].src;
            if (/(\/ec\.js|^ec\.js)$/.test(parser.pathname)) {
                if (parser.search.length > 1) {
                    args = parser.search.substr(1).split('&') || [];
                    break;
                }
            }
            parser = null;
        }
        args.forEach(function(a) {
            let kv = a.split('=');
            query[kv[0]] = kv[1];
        });
        return query;
    }

    function getCrossDomainAttribute(crossDomain, brand, country, domain) {
        let res = true;
        if (typeof crossDomain != "undefined" && [0,1].indexOf(crossDomain*1) >= 0) return !!+crossDomain;
        brands.forEach(b => {
            if (b.name === brand) {
                if (b.crossDomains === false) res = false;
                else if (b.crossDomains && b.crossDomains !== false) {
                    if (b.crossDomains.countries && b.crossDomains.countries[country] === false) {
                        res = false;
                    }
                    else res = !(b.crossDomains.domains && b.crossDomains.domains[domain] === false);
                }
            }
        });
        return res;
    }

    function getFXTracking() {
        var res = [];
        if(window.fxtracking) {
            var gclid = getParam('gclid'), aqid = getParam('aqid');
            if (gclid) {
                res.push(['extid2='+gclid]);
            }
            if (aqid) {
                res.push(['extid3='+aqid]);
            }

            if (window.saprocess || (window.fxo && window.fxo.sa) ) {
                window.saprocess = 1;
                res.push(['dsp23', window.saprocess]);
            }
            for(var i=0;i<window.fxtracking.length;i++) {
                res.push(window.fxtracking[i][0]+'='+encodeURIComponent(window.fxtracking[i][1]));
            }
        }
        return !res.length ? '' : '&'+res.join('&');
    }

    let query = getQueryStringParameters();
    let crossDomain = getCrossDomainAttribute(query.cr,query.brand || 'iforex',readCookie('geoip'), document.domain);

    window.emerpq = window.emerpq || [];

    window._fxreferrer = '';
    try { window._fxreferrer = encodeURIComponent(document.referrer); } catch(x) { window._fxreferrer = 'not-provided'; }

    var ec = document.createElement('iframe');

    ec.id = 'emerpec';
    ec.name = 'emerpec';
    ec.setAttribute('frameborder', '0');
    ec.setAttribute('width', '0');
    ec.setAttribute('height', '0');
    ec.setAttribute('style', 'position:absolute; top:0; left:0;');
    ec.src = 'about:blank';
    if (typeof window.fastdom === 'object') fastdom.mutate(() => document.body.appendChild(ec));
    else document.body.appendChild(ec);


    window.SendEvent = function(eventname, username) {
        try {
            if (eventname === 'hphit') eventname = '%3Fhphit';
            emerpec.document.location = "/emerp/events/emerpec.html?p=0&EventName=" + eventname + "&DoOnSuccess=true&account=" + username;
        } catch (ex) {}
    };

    window.handleSuccess = function(comp, eventname) {
        window["promocode"] = comp.GetUserID();
        window['serial'] = comp.GetSerialID();
        window['emerpcomp'] = comp;
        typeof(window.handleEMERPEvent) === 'function' && window.handleEMERPEvent(eventname);
        try { dataLayer.push({"event":"emerpready", "eventname":eventname}); } catch (e) { }
    };

    window.emerpcomp = null;

    var sourcedomain = "https://" + document.location.host;
    var originaldomain = "https://" + "events.iforex.com";
    var tmpSearch = document.location.search.toLocaleLowerCase();
    var m = tmpSearch.match(/[?&]sid=([^&]*)/i);
    var serial = (m != null) ? m[1] : "";
    var r = document.referrer;
    try {
        if (r !== "" && !r.match(/^http[s]?:\/\//)) r = decodeURIComponent(r);
        r = encodeURIComponent(r);
    } catch (e) {}
    if (document.location.search.indexOf('redir=') !== -1) r = '';
    var inIfr = tmpSearch.match(/ifrm=([^&]*)/);
    inIfr = (inIfr != null) ? inIfr[1] : "";
    console.log(sourcedomain, originaldomain);
    if (inIfr === "") {
        if (crossDomain && sourcedomain.indexOf('localhost') < 0 && sourcedomain !== originaldomain) {
            document.getElementById("emerpec").src = originaldomain + "/emerp/events/emerpec.html?curr=" + encodeURIComponent(document.location.href) + "&ref=" + r + "&" + document.location.search.substring(1) + "&SID=" + serial + getFXTracking() + "&return=" + encodeURIComponent(sourcedomain + "/emerp/events/emerpec.html?p=0&DoOnSuccess=true&EventName=&userguid=[guid]&SID=[sid]&[dsp]");
        } else {
            var tmpParams = "&EventName=";
            document.getElementById("emerpec").src = "/emerp/events/emerpec.html?curr=" + encodeURIComponent(document.location.href) + "&ref=" + r + getFXTracking() + "&p=0&DoOnSuccess=true&masterdomain=null" + tmpParams;
        }
    }
};
export default EC();
